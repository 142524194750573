<template>
  <div class="facebook-post-list-data-table position-relative w-100" :style="`height: ${height}`">
    <fluency-loader v-if="loadingPagePostData" />
    <fluent-table v-else
                  ref="postTable"
                  :plan-type="tableType"
                  primary-key="fbPageFeedId"
                  :use-flex-classes="true"
                  :fixed-header="true"
                  :fixed="true"
                  :table-toolbar="toolbarConfiguration"
                  :select-mode="singleSelect ? 'single' : 'multi'"
                  :fields="fbPostFields"
                  :items="fbPostData">
        <template #cell(picture)="row">
          <row-details-expander v-if="!selectMode" class="d-flex align-items-start row-expander position-relative"
                                v-bind="row"
                                @toggle-details="loadPostDetailsData(row.item); clearQuickPromote();">
            <img :src="row.value"
                 loading="lazy"
                 class="img-thumbnail img-square-thumbnail fallback"
                 alt=" "/>
            <div v-if="isPostAttachmentVideo(row.item)" class="overlay">
              <fluency-icon type="caretRight" class="video-indicator" />
            </div>
          </row-details-expander>
          <template v-else>
            <img :src="row.value"
                 loading="lazy"
                 class="img-thumbnail img-square-thumbnail fallback"
                 alt=" "/>
            <div v-if="isPostAttachmentVideo(row.item)" class="overlay">
              <fluency-icon type="caretRight" class="video-indicator" />
            </div>
          </template>
        </template>
        <template #cell(message)="row">
          <div class="clamped-message">
            {{row.value}}
          </div>
        </template>

      <template #row-details="row">
        <div class="text-left p-3 d-flex">
          <div class="border post-container">
            <div class="px-5 pt-3">
              <div class="post-header d-flex align-items-center">
                <img :src="getPagePicture(row.item)"
                     loading="lazy"
                     alt="account page image" />
                <div class="ml-3 flex-grow-1">
                  <div class="d-flex ">
                    <span class="font-size-larger flex-grow-1">{{ getPageName(row.item) }}</span>
                    <a v-if="row.item.attachments.length > 0" :href="row.item.attachments[0].unshimmed_url" target="_blank" class="ml-3">View Post <fluency-icon type="linkOut" /></a>
                  </div>
                  <div class="text-muted">{{$moment(row.item.createdOn).toNow(true)}}</div>
                </div>
              </div>
              <p class="font-size-larger mt-3 white-space-normal">{{row.item.message}}</p>
            </div>
            <div class="scaled-post-image-container">
              <video v-if="isPostAttachmentVideo(row.item)"
                     :src="pageFeedIdToVideoUrlMap[row.item.fbPageFeedId]"
                     :poster="row.item.picture"
                     :controls="true"/>
              <img v-else
                   :src="row.item.picture"
                   loading="lazy"
                   alt="full size image" />
            </div>
            <div class="p-3">
              <p-chip v-for="(reactionValue, reactionKey) in getPostReactions(row.item)"
                      :key="reactionKey">
                <img :src="`/img/fb-reactions/${reactionKey.replace('reaction', '').toLowerCase()}.png`" height="16" />
                <span class="pt-1 reaction-value">{{reactionValue}}</span>
              </p-chip>
            </div>
          </div>
          <div class="px-5 py-3 flex-grow-1 d-flex flex-column">

            <promoted-post-campaign-ad-set-chooser :account-plan-id="accountPlanId"
                                                     @selected="quickPromoteCampaign = $event.campaign; quickPromoteAdSet = $event.adSet" />
            <promoted-post-cta v-if="requireCta" v-model="row.item.callToAction"></promoted-post-cta>

            <t-button variant="primary"
                      :disabled="!quickPromoteCampaign || !quickPromoteAdSet || (!row.item.callToAction && requireCta)"
                      @click="promotePosts([row.item])"
                      class="align-self-end">
              <fluency-icon type="lightningBolt" /> Promote Post
            </t-button>

            <div>

            </div>
          </div>
        </div>
      </template>
    </fluent-table>

    <t-modal :visible="showPopover"
             @update:visible="showPopover = $event"
             lazy
             size="sm"
             :title="`Promote ${selectedRows.length} Post${selectedRows.length === 1 ? '' : 's'}`"
             centered
             ok-only
             body-class="overflow-visible"
             @hidden="quickPromoteCta = undefined">
      <div class="d-flex flex-column">

        <promoted-post-campaign-ad-set-chooser class="d-flex flex-column" style="width: 450px"
                                               :account-plan-id="accountPlanId"
                                               @selected="quickPromoteCampaign = $event.campaign; quickPromoteAdSet = $event.adSet"/>
        <promoted-post-cta v-if="requireCta" @input="quickPromoteCta = $event"></promoted-post-cta>
      </div>

      <template #modal-footer>
        <t-button variant="primary"
                  :disabled="!quickPromoteCampaign || !quickPromoteAdSet || (!quickPromoteCta && requireCta)"
                  class="align-self-end"
                  @click="promotePosts(selectedRows)">
          <fluency-icon type="lightningBolt"/>
          Promote Posts
        </t-button>
      </template>
    </t-modal>
  </div>
</template>

<script>
import RowDetailsExpander from '@/components/table/cells/rowDetailsExpander'
import PromotedPostCampaignAdSetChooser from '@/components/common/promotedPostCampaignAdSetChooser'
import FluencyLoader from 'core-ui/components/common/fluencyLoader'
import _delay from 'lodash.delay'
import PromotedPostCta from '@/components/promotedPostCta'
import PopoverCancelSave from './popoverCancelSave.vue'

export default {
  name: 'facebookPostListDataTable',
  components: {
    PromotedPostCta,
    FluencyLoader,
    PromotedPostCampaignAdSetChooser,
    RowDetailsExpander,
    PopoverCancelSave
  },
  props: {
    selectMode: {
      type: Boolean,
      default: false
    },
    singleSelect: { // if selectMode is on, only allow one post to be selected. the default is to allow multiple posts to be selected
      type: Boolean,
      default: false
    },
    resize: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      height: '70vh',
      tableType: 'facebookPost',
      bulkPromotePostsButtonId: 'bulk-promote-posts-button',
      infoOverlays: {},
      fbPostData: [],
      selectedPost: null,
      quickPromoteCampaign: null,
      quickPromoteAdSet: null,
      quickPromoteCta: undefined,
      pageFeedIdToVideoUrlMap: {},
      partnerPageMap: {},
      mountBulkPopover: false,
      showPopover: false,
      loadingPagePostData: false
    }
  },
  watch: {
    accountPlanId: {
      immediate: true,
      handler: function (newVal, oldVal) {
        if (newVal && newVal !== oldVal) {
          this.loadPagePosts({ accountPlanId: newVal })
        }
      }
    },
    'selectedRows.length': {
      immediate: true,
      handler: function (newVal, oldVal) {
        if (!this.selectMode) {
          if (newVal > 0) {
            setTimeout(() => { this.mountBulkPopover = true }, 100)
          } else {
            this.mountBulkPopover = false
          }
        }
        if (this.selectMode && this.singleSelect) {
          this.$emit('selected', newVal > 0 ? this.selectedRows[0] : null)
        } else {
          this.$emit('selected', this.selectedRows)
        }
      }
    },
    managePaneSize: {
      handler: function (newVal, oldVal) {
        if (newVal !== oldVal && this.resize) {
          _delay(() => {
            const contentPane = document.querySelector('.settings-pane')
            if (contentPane) {
              this.height = `${contentPane.offsetHeight - 140}px`
            }
          }, 300)
        }
      },
      immediate: true
    }
  },
  beforeUnmount () {
    if (this.$refs.postTable) {
      this.$refs.postTable.deselectAll()
    }
  },
  computed: {
    accountPlanId () {
      return this.$attrs['account-plan-id'] || this.$store.getters.settingsItem?.accountPlanId
    },
    managePaneSize () {
      return this.$store.getters.manageStatsPaneSize
    },
    selectedRows () {
      return this.$store.getters['table/selectedRowObjs'](this.tableType) || []
    },
    fbPostFields () {
      return [
        { slot: 'selectRow', defaultWidth: 10, sticky: false },
        {
          key: 'picture',
          label: 'Post',
          resize: false,
          defaultWidth: 25,
          sortable: false,
          sortByFormatted: (val, key, item) => this.isPostAttachmentVideo(item) ? 'video' : 'image',
          filterByFormatted: (val, key, item) => this.isPostAttachmentVideo(item) ? 'video' : 'image',
          filter: true,
          filterOptions: [[{ text: 'Video Posts', value: 'video' }, { text: 'Image Posts', value: 'image' }]]
        },
        { key: 'message', tdClass: 'overflow-hidden text-overflow-ellipsis', filter: true, filterOptions: ['string'] },
        { key: 'impressionsUnique', label: 'Reach', defaultWidth: 20, tdClass: 'font-numeric', class: 'text-right' },
        { key: 'impressionsPaidUnique', label: 'Paid Reach', defaultWidth: 20, formatter: (val) => val === 0 ? '' : val, tdClass: 'font-numeric', class: 'text-right' },
        {
          key: 'reactions',
          defaultWidth: 30,
          tdClass: 'font-numeric',
          class: 'text-right',
          formatter: (val, key, item) => Object.keys(item).filter(key => key.includes('reaction')).sum(key => item[key]),
          sortByFormatted: true // (val, key, item) => Object.keys(item).filter(key => key.includes('reaction')).sum(key => item[key])
        },
        { key: 'createdOn', defaultWidth: 50, formatter: (val) => this.$moment(val).format('MMM Do, YYYY hh:mm a') }
      ]
    },
    toolbarConfiguration () {
      if (this.selectMode) {
        return null
      }
      return [{
        label: 'Promote Posts',
        action: () => { this.showPopover = true }
      }]
    },
    requireCta () {
      return this.quickPromoteCampaign && this.quickPromoteCampaign.goal !== 'POST_ENGAGEMENT'
    }
  },
  methods: {
    async loadPagePosts ({ accountPlanId }) {
      this.loadingPagePostData = true
      const pagePosts = await this.$res.fetch.facebookPagePosts({ accountPlanId })
      if (pagePosts) {
        pagePosts.sort((a, b) => this.$moment(a.createdOn).isBefore(this.$moment(b.createdOn)) ? 1 : -1)
        const pageIds = new Set()
        const partnerPageFetches = []
        pagePosts.forEach(async post => {
          if (post.attachments?.length > 0 && post.attachments.startsWith('[{')) {
            post.attachments = JSON.parse(post.attachments)
          } else {
            post.attachments = []
          }
          if (!pageIds.has(post.fbPageReferenceId)) {
            pageIds.add(post.fbPageReferenceId)
            partnerPageFetches.push(this.fetchPartnerPage(post))
          }
        })
        await Promise.all(partnerPageFetches)
        this.fbPostData.splice(0, this.fbPostData.length, ...pagePosts)
      }
      this.loadingPagePostData = false
    },
    loadPostDetailsData (post) {
      this.exchangeVideoIdForUrl(post)
      this.fetchPartnerPage(post)
    },
    getPostReactions (post) {
      return Object.keys(post)
        .filter(key => key.includes('reaction') && post[key] > 0)
        .toMap(key => key, key => post[key])
    },
    isPostAttachmentVideo (post) {
      if (post.attachments.length > 0) {
        return post.attachments[0].type?.includes('video')
      }
      return false
    },
    async exchangeVideoIdForUrl (post) {
      if (this.isPostAttachmentVideo(post)) {
        const videoId = post.attachments[0].target?.id
        if (videoId && !this.pageFeedIdToVideoUrlMap[post.fbPageFeedId]) {
          const res = await this.$res.fetch.exchangeVideoIdForUrl({ accountPlanId: this.accountPlanId, videoId })
          if (res) {
            this.$setCompat(this.pageFeedIdToVideoUrlMap, post.fbPageFeedId, res.url)
          }
        }
      }
    },
    async fetchPartnerPage (post) {
      if (!this.partnerPageMap[post.fbPageReferenceId]) {
        const pages = await this.$res.fetch.facebookPages({ accountPlanId: this.accountPlanId, facebookPageId: post.fbPageReferenceId })
        if (pages?.length > 0) {
          this.$setCompat(this.partnerPageMap, post.fbPageReferenceId, pages[0])
        }
      }
    },
    getPagePicture (post) {
      if (this.partnerPageMap[post.fbPageReferenceId]) {
        return this.partnerPageMap[post.fbPageReferenceId].coverPhoto
      }
      return post.picture
    },
    getPageName (post) {
      return this.partnerPageMap[post.fbPageReferenceId]?.name ?? ''
    },
    async promotePosts (posts = []) {
      this.$eventBus.$emit('bv::hide::popover', this.bulkPromotePostsButtonId)
      const promotedPosts = this.requireCta && this.quickPromoteCta ? posts.map(p => ({ ...p, callToAction: this.quickPromoteCta, attachments: JSON.stringify(p.attachments) })) : posts.map(p => ({ ...p, attachments: JSON.stringify(p.attachments) }))
      const response = await this.$res.set.quickPromotePosts({
        accountPlanId: this.accountPlanId,
        adGroupPlanId: this.quickPromoteAdSet.adGroupPlanId,
        promotedPosts
      })
      if (response) {
        this.$toast(`${promotedPosts.length} post${promotedPosts.length > 1 ? 's' : ''} successfully promoted`, 'success')
        this.quickPromoteCta = undefined
      }
      this.clearQuickPromote()
      if (this.$refs.postTable) {
        this.$refs.postTable.deselectAll()
      }
    },
    clearQuickPromote () {
      this.quickPromoteCampaign = null
      this.quickPromoteAdSet = null
    }
  }
}
</script>

<style lang="scss" scoped>

.thumbnail-container {
  width:58px;
  height:58px;
}

.img-square-thumbnail {
  width: 58px;
  height: 58px;
  object-fit: cover;
}

.fallback:before {
  content: ' ';
  display: block;
  position: absolute;
  width: 47px;
  height: 47px;
  background-color: white;
  background-image: url(core-ui/assets/img/image-placeholder-47x47.png);
  background-size: cover;
}

.clamped-message {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  white-space: normal;
}
.reaction-value {
  font-size: 130%;
  color: gray;
}

.post-container {
  width: 494px;
  height: auto;
  flex: 0 0 494px;

  .post-header {
    img {
      height: 40px;
      width: 40px;
      border-radius: 50%;
      object-fit: cover;
    }
  }

  .scaled-post-image-container {
    background-color: $fluency-lightergray;

    img, video {
      max-width: 494px;
      max-height: 278px;
      width: 100%;
      height: auto;
      object-fit: scale-down;
    }
  }

  .font-size-larger {
    font-size: larger;
  }

  .white-space-normal {
    white-space: normal;
  }

}

.overlay {
  position: absolute;
  top: 8px;
  bottom: 0;
  left: 23px;
  right: 0;
  height: 100%;
  width: 100%;
  background-color: transparent;
  opacity: .8;
  overflow: auto;
}

.video-indicator {
  position: absolute;
  top: 8px;
  left: 12px;
  font-size: 28px;
  color: white;
  text-align: center;

  &.video-indicator-lg {
    font-size: 150px;
    top: 48px;
    left: 96px;
  }
}
</style>
<style lang="scss">
.facebook-post-list-data-table .row-expander button {
  margin-top: .2rem; // bump down the row expander button so its inline with the checkbox
}
</style>
